import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import financialadvisory from "../assets/images/financialadvisory1.jpg";
import "./Advisory.css";
import {BsDot} from 'react-icons/bs';
import NavbaR from "./Navbar/NavbaR";

function Advisory() {
  return (
    <div className="strategy-main">
      <div className="financial-top-div">
        <div><NavbaR/></div>
        <h1 className="strategy-label">Services We Offer</h1>
      </div>

      <div className="strategy-evaluation-main">
        <h3 className="strategy-evaluation-title">Financial Advisory</h3>
        <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
        <p className="evaluation-details">Eram Capital Advisors Lux offers clients its expertise in arranging financing for unique global special situations investment opportunities with global institutional and family-office investors. We focus on core sectors and specific opportunities where the growth prospects are being driven by long-term, globally-oriented and growth-oriented demographic factors. We identify investment opportunities that suit our investors risk appetite and preference along with our own stringent quality control criteria. Eram Capital Advisors Lux has local presence in the geographies in which we focus and we leverage this expertise to provide appropriate governance structures to ensure the active level of management of the investment.
        </p>
      </div>

      <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Identification and structuring of investment opportunities.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Corporate finance structuring and capital raising.</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Equity and debt structuring solutions.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Evaluation of business development scenarios and identification of strategic partners.</p>
        </div>
      </div>   

    </div>
  );
}

export default Advisory;
