import React from "react";
import video from "../assets/videos/video.mp4";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Home.css";
import Tagline from "./Tagline";
import NavbaR from "./Navbar/NavbaR";
import Tagline1 from "./Tagline/Tagline1";
import { Sectors } from "./Sectors/Sectors";
import {BsArrowRight} from 'react-icons/bs';

function Home() {
  return (
    <div className="home-main">
      <div className="home-navbar-div"><NavbaR/></div>
      <div className="home-top-div">
      <div className="home-video-div">
          <video
            width="100%"
            autoPlay="true"
            loop
            style={{ height: "90vh", objectFit: "cover" }}
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="home-top-card">
        <h4 className="home-top-card-title">Facilitating the deployment of investment capital across  geographies and asset classes</h4>
        <div className="home-top-know-more-div">
          <a href="#about-us-main-id" style={{ textDecoration:'none', color:'white' }}>
        <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
        </a>
        </div>
      </div>
      <Tagline1 />
      <Sectors/>
      </div>
    
    // <div>
    //   <div className="home__border"></div>
    //   <Container className="home__container">
        // <div className="home__vid">
        //   <video
        //     width="100%"
        //     autoPlay="true"
        //     loop
        //     style={{ height: "500px", objectFit: "cover" }}
        //   >
        //     <source src={video} type="video/mp4" />
        //   </video>
        // </div>
    //     <Tagline />

    //     <div className="card_div">
    //       <h2>Sectors We Work In</h2>
    //       <Row>
    //         <Col md={6} lg={3} className="card">
    //           <img src={estate} className="home__img" alt="Real Estate" />
    //           <div className="button_div">
    //             <Link to="/estate">
    //               <Button className="button">REAL ESTATE</Button>
    //             </Link>
    //           </div>
    //         </Col>
    //         <Col md={6} lg={3} className="card">
    //           <img
    //             src={infrastructure}
    //             className="home__img"
    //             alt="infrastructure"
    //           />
    //           <div className="button_div">
    //             <Link to="/infrastructure">
    //               <Button className="button">INFRASTRUCTURE</Button>
    //             </Link>
    //           </div>
    //         </Col>
    //         <Col md={6} lg={3} className="card">
    //           <img src={agriculture} className="home__img" alt="Agriculture" />
    //           <div className="button_div">
    //             <Link to="/agriculture">
    //               <Button className="button">AGRICULTURE</Button>
    //             </Link>
    //           </div>
    //         </Col>
    //         <Col md={6} lg={3} className="card">
    //           <img src={energy} className="home__img" alt="Energy" />
    //           <div className="button_div">
    //             <Link to="/energy">
    //               <Button className="button">ENERGY</Button>
    //             </Link>
    //           </div>
    //         </Col>
    //       </Row>
    //     </div>
    //   </Container>
    // </div>
  );
}

export default Home;
