import React from "react";
import { useHistory } from "react-router-dom";
import finance from '../assets/images/financial1.jpg';
import consulting from '../assets/images/consulting1.jpg';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Firm.css";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';
import {BsArrowRight} from 'react-icons/bs';

function Firm() {
  const history = useHistory();

  return (
    <div className="strategy-main">
    <div className="services-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Our Firm</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Importance of Defining Investment Strategy</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital Advisors Lux is a global special situations advisory company focusing on assessing unique investment opportunities for global institutional and family-office co-investors. We believe in creating lasting value for our clients through prudent investment advice for their capital. We advise across various sectors from private equity, real estate to energy and yield superior benefits for our clients.
      </p>
    </div>

    <div className="sectors-main">
    <div className='sector-cards'>
            
            <div className='sector-card-main' onClick={()=>{ history.push('financial-advisory')}}>
                <img src ={finance} alt='img' className='sector-card-img' />
                <div className='sector-card-know-more'>
                <p className="home-top-know-more" id='home-top-know-more-id'> Know more   <BsArrowRight/></p>
                </div>
                <div className='sector-card-detail' id=''>  
                    <h3 className='sector-card-id'>01</h3>
                    <h4 className='sector-card-title'>Financial Advisory</h4>
                </div>
                
            </div>

            <div className='sector-card-main' onClick={()=>{ history.push('consulting-services')}}>
                <img src ={consulting} alt='img' className='sector-card-img' />
                <div className='sector-card-know-more'>
                <p className="home-top-know-more" id='home-top-know-more-id'> Know more   <BsArrowRight/></p>
                </div>
                <div className='sector-card-detail' id=''>  
                    <h3 className='sector-card-id'>02</h3>
                    <h4 className='sector-card-title'>Consulting Services</h4>
                </div>
                
            </div>
    
    </div>
    </div>
  </div>   
  );
}

export default Firm;

