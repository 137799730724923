import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import consultingservice from "../assets/images/consultingfirm1.jpg";
import "./Services.css";
import {BsDot} from 'react-icons/bs';
import NavbaR from "./Navbar/NavbaR";

function Services() {
  return (
    <div className="strategy-main">
    <div className="services-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Services We Offer</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Consulting Services</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital Advisors Lux identifies investment and partnership opportunities based on a number of key strategic factors to determine the suitability for its investor partners as well as for the company or project sponsor seeking investment. Some of the primary factors are:
      </p>
    </div>


    <div className="evaluation-points-main">
    <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Business Strategy:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Clarity and longevity of strategy.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Feasibility.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Innovation</p>
      </div>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Complementary fit with our other investments:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Opportunities for the domestic market.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Global sector synergies.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Willingness to develop partnerships.</p>
      </div>
      </div>


      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Growth Capital Expansion:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Marginal impact of capital investment on growth.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Life cycle of business plan.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Magnitude of capital requirement.</p>
      </div>
      </div>


      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Strategic Sector or Geography:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Growth cycle of sector or region.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Demographic drivers.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Sustainability.</p>
      </div>
      </div>

  </div>

      <p className="evaluation-details"  id='evaluation-details-id'>Eram Capital Advisors Lux and its investor partners focus on companies and projects, either directly or via portfolio investments through sponsors, that exhibit strong bias toward sustainable growth and feasibility of success over the medium term.
    </p>

  </div>
  );
}

export default Services;
