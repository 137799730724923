import React from "react";
import { useHistory } from "react-router-dom";
import "./Sectors.css";
import realEstate from "../../assets/images/Real-estate.png";
import infra from "../../assets/images/infra.png";
import agri from "../../assets/images/agriculture.png";
import energy from "../../assets/images/energy.png";
import finance from "../../assets/images/financial1.jpg";
import consulting from "../../assets/images/consulting1.jpg";
import { BsArrowRight } from "react-icons/bs";

export const Sectors = () => {
  const history = useHistory();

  return (
    <div className="sectors-main">
      <h1>Services We Offer</h1>
      <div className="title-placer">&nbsp;</div>

      <div className="sector-cards">
        <div
          className="sector-card-main"
          onClick={() => {
            history.push("financial-advisory");
          }}
        >
          <img src={finance} alt="img" className="sector-card-img" />
          <div className="sector-card-know-more">
            <p className="home-top-know-more" id="home-top-know-more-id">
              {" "}
              Know more <BsArrowRight />
            </p>
          </div>
          <div className="sector-card-detail" id="">
            <h3 className="sector-card-id">01</h3>
            <h4 className="sector-card-title">Financial Advisory</h4>
          </div>
        </div>

        <div
          className="sector-card-main"
          onClick={() => {
            history.push("consulting-services");
          }}
        >
          <img src={consulting} alt="img" className="sector-card-img" />
          <div className="sector-card-know-more">
            <p className="home-top-know-more" id="home-top-know-more-id">
              {" "}
              Know more <BsArrowRight />
            </p>
          </div>
          <div className="sector-card-detail" id="">
            <h3 className="sector-card-id">02</h3>
            <h4 className="sector-card-title">Consulting Services</h4>
          </div>
        </div>
      </div>
    </div>
  );
};
