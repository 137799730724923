import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import Advisory from "./Components/Advisory";
import Contact from "./Components/Contact";
import Cookie from "./Components/Cookie";
import Firm from "./Components/Firm";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Navbar1 from "./Components/Navbar1";
import Policy from "./Components/Policy";
import ScrollToTop from "./Components/ScrollToTop";
import Services from "./Components/Services";
import { AboutUs } from "./Components/About/AboutUs";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
  
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/firm" component={Firm}></Route>
          <Route exact path="/financial-advisory" component={Advisory}></Route>
          <Route exact path="/consulting-services" component={Services}></Route>
          <Route exact path="/contact" component={Contact}></Route>
          <Route exact path="/privacy-policy" component={Policy}></Route>
        </Switch>
        <AboutUs />
      </BrowserRouter>
      <Cookie />
    </div>
  );
}

export default App;
