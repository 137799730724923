import React from "react";
import ERAM from "../assets/images/capitallux.jpg";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <Container className="footer__main">
      <Row className="footer__sub">
        <Col md={5} className="footer__first">
          <img src={ERAM} alt="logo" className="footer__image"></img>

          <div className="footer__add">
            <div className="footer__icon">
              <i
                className="fas fa-map-marked-alt"
                style={{ color: "#80c2fa" }}
              ></i>
            </div>
            <div>
              International,52 Rue D'Anvers,
              <br />
              L-1130, Luxembourg
            </div>
          </div>
          <div className="footer__add">
            <div className="footer__icon">
              <i className="fas fa-envelope" style={{ color: "#80c2fa" }}></i>
            </div>
            <div>info@eramcapitallux.com</div>
          </div>
          <div className="footer__copy">
            Eram Capital Advisors © 2021. All rights reserved.
          </div>
        </Col>

        <Col md={7} className="footer__second">
          <h3>About Us</h3>
          <br />
          <p>
            Eram Capital is an investment advisory company with global reach
            focusing on special situations opportunities. Eram brings to its
            clients and partners unique investment advisory expertise across
            sectors.
          </p>

          <Link to="/privacy-policy">
            <Button className="footer__pol">Privacy Policy</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
