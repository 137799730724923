import React from "react";
import { Container, Table } from "react-bootstrap";
import "./Policy.css";

function Policy() {
  return (
    <div>
      <div className="pol__left">
        <h1>Privacy Policy</h1>
      </div>
      <Container className="pol__body">
        <p className="pol__para pol__head">Introduction</p>
        <p className="pol__para">
          Welcome to the Eram Capital Advisory' privacy notice.
        </p>
        <p className="pol__para">
          Eram Capital Advisory Limited respects your privacy and is committed
          to protecting your personal data. This privacy notice will inform you
          as to how we look after your personal data when you visit our website
          (regardless of where you visit it from), or when you provide personal
          data to us through telephone and email communications; and this
          privacy notice tells you about your privacy rights and how the law
          protects you.
        </p>
        <p className="pol__para">
          Please also use the Glossary to understand the meaning of some of the
          terms used in this privacy notice.
        </p>
        <ol>
          <li>IMPORTANT INFORMATION AND WHO WE ARE</li>
          <li>THE DATA WE COLLECT ABOUT YOU</li>
          <li>HOW IS YOUR PERSONAL DATA COLLECTED</li>
          <li>HOW WE USE YOUR PERSONAL DATA</li>
          <li>DISCLOSURES OF YOUR PERSONAL DATA</li>
          <li>INTERNATIONAL TRANSFERS</li>
          <li>DATA SECURITY</li>
          <li>DATA RETENTION</li>
          <li>YOUR LEGAL RIGHTS</li>
          <li>GLOSSARY</li>
        </ol>
        <ol>
          <li className="pol__head">Important information and who we are</li>
          <p className="pol__para">Purpose of this privacy notice</p>
          <p className="pol__para">
            This privacy notice aims to give you information on how Eram Capital
            Advisory Limited collects and processes your personal data through
            your use of this website, including any data you may provide through
            this website when you submit a query or take part in a competition;
            and how we collect and process any personal data you may provide by
            email and telephone communications.
          </p>
          <p className="pol__para">
            This website is not intended for children and we do not knowingly
            collect data relating to children.
          </p>
          <p className="pol__para">
            It is important that you read this privacy notice so that you are
            fully aware of how and why we are using your data.
          </p>
          <p className="pol__para">Controller</p>
          <p className="pol__para">
            Eram Capital Advisory Limited is the controller and responsible for
            your personal data (collectively referred to as “Company”, “we”,
            “us” or “our” in this privacy notice).
          </p>
          <p className="pol__para">
            We have appointed a data protection officer (DPO) who is responsible
            for overseeing questions in relation to this privacy notice. If you
            have any questions about this privacy notice, including any requests
            to exercise your legal rights, please contact the DPO using the
            details set out below.
          </p>
          <p className="pol__para">Contact details</p>
          <p className="pol__para">Our full details are:</p>
          <p className="pol__para">
            Full name of legal entity: Eram Capital Advisory Limited
          </p>
          <p className="pol__para">
            Name or title of DPO: Jeebin Park, Finance Manager
          </p>
          <p className="pol__para">Email address: jeebin@eramcapital.com</p>
          <p className="pol__para">
            Postal address: international 52 Rue D'Anvers, L-1130, Luxembourg
          </p>
          <p className="pol__para">
            You have the right to make a complaint at any time to the
            Information Commissioner’s Office (ICO), the UK supervisory
            authority for data protection issues (www.ico.org.uk). We would,
            however, appreciate the chance to deal with your concerns before you
            approach the ICO so please contact us in the first instance.
          </p>
          <p className="pol__para">
            Changes to the privacy notice and your duty to inform us of changes
          </p>
          <p className="pol__para">
            This version was last updated in September 2021.
          </p>
          <p className="pol__para">
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us.
          </p>
          <p className="pol__para">Third-party links</p>
          <p className="pol__para">
            This website may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We do
            not control these third-party websites and are not responsible for
            their privacy statements. When you leave our website, we encourage
            you to read the privacy notice of every website you visit.
          </p>
          <li className="pol__head">The data we collect about you</li>
          <p className="pol__para">
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
          </p>
          <p className="pol__para">
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together as follows:
          </p>
          <ul>
            <li>
              Identity Data includes first name, last name, title, date of birth
              and gender.
            </li>
            <li>
              Contact Data includes, billing address, delivery address, email
              address, telephone numbers, clinic details, hospital type, and
              website URL.
            </li>
            <li>Financial Data includes bank account details.</li>
            <li>
              Transaction Data includes details about payments to and from you
              and other details of products and services you have purchased from
              us.
            </li>
            <li>
              Technical Data includes internet protocol (IP) address, browser
              type and version, location, operating system and platform on the
              devices you use to access this website. We may also collect log
              file for our machines, machine firmware and software version, raw
              data for problem image if necessary using Windows version and PMS
              version.
            </li>
            <li>
              Profile Data includesyour purchases or orders made by you, your
              interests, preferences, feedback and survey responses.{" "}
            </li>
            <li>
              Usage Data includes information about how you use our products and
              services.
            </li>
          </ul>
          <p className="pol__para">
            We do not collect any Special Categories of Personal Data about you
            (this includes details about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health and
            genetic and biometric data). Nor do we collect any information about
            criminal convictions and offences.
          </p>
          <p className="pol__para">If you fail to provide personal data</p>
          <p className="pol__para">
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            goods or services). In this case, we may have to cancel a product or
            service you have with us but we will notify you if this is the case
            at the time.
          </p>
          <li className="pol__head">How is your personal data collected?</li>
          <p className="pol__para">
            We use different methods to collect data from and about you
            including through:
          </p>
          <ul>
            <li>
              Direct interactions. You may give us your Identity, Contact and
              Financial Data by filling in forms or by corresponding with us by
              post, phone, email or otherwise. This includes personal data you
              provide when you:
            </li>
            <li>apply for our services;</li>
            <li>subscribe to our service or publications;</li>
            <li>enter a competition, promotion or survey; or</li>
            <li>give us some feedback.</li>
            <li>
              As you interact with our website, we may automatically collect
              Technical Data about your equipment, browsing actions and
              patterns. We collect this personal data by copying the data from a
              corresponding folders using engineer software. We do not use
              cookies on our website.
            </li>
            <li>
              Third parties or publicly available sources. We may receive
              personal data about you from various third parties and public
              sources as set out below:
            </li>
            <li>Technical Data from the following parties:</li>
            <li>
              Identity and Contact Data from publicly availably sources such as
              Companies House and the Electoral Register based inside the EU.
            </li>
          </ul>
          <li className="pol__afterlist pol__head">
            How we use your personal data
          </li>
          <p className="pol__para">
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>
          <ul>
            <li>
              Where we need to perform the contract, we are about to enter into
              or have entered into with you.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </li>
            <li>
              Where we need to comply with a legal or regulatory obligation.
            </li>
          </ul>
          <p className="pol__para">
            Generally we do not rely on consent as a legal basis for processing
            your personal data.
          </p>
          <p className="pol__para">
            Purposes for which we will use your personal data
          </p>
          <p className="pol__para">
            We have set out below, in a table format, a description of all the
            ways we plan to use your personal data, and which of the legal bases
            we rely on to do so. We have also identified what our legitimate
            interests are where appropriate.
          </p>
          <p className="pol__para">
            Note that we may process your personal data for more than one lawful
            ground depending on the specific purpose for which we are using your
            data. Please contact us if you need details about the specific legal
            ground we are relying on to process your personal data where more
            than one ground has been set out in the table below.
          </p>
          <Table striped bordered hover className="pol__table">
            <thead>
              <tr>
                <th>Purpose/Activity</th>
                <th>Type of data</th>
                <th>
                  Lawful basis for processing including basis of legitimate
                  interest
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>To register you as a new customer</td>
                <td>(a) Identity (b) Contact</td>
                <td>Performance of a contract with you</td>
              </tr>
              <tr>
                <td>
                  To process and deliver your order including: (a) Manage
                  payments, fees and charges (b) Collect and recover money owed
                  to us
                </td>
                <td>(a) Identity (b) Contact (c) Financial (d) Transaction</td>
                <td>
                  (a) Performance of a contract with you (b) Necessary for our
                  legitimate interests (to recover debts due to us)
                </td>
              </tr>
              <tr>
                <td>
                  To manage our relationship with you which will include: (a)
                  Notifying you about changes to our terms or privacy policy{" "}
                </td>
                <td>(a) Identity (b) Contact (c) Profile</td>
                <td>
                  (a) Performance of a contract with you (b) Necessary to comply
                  with a legal obligation (c) Necessary for our legitimate
                  interests (to keep our records updated)
                </td>
              </tr>
              <tr>
                <td>To enable you to complete a survey</td>
                <td>(a) Identity (b) Contact (c) Profile (d) Usage </td>
                <td>
                  (a) Performance of a contract with you (b) Necessary for our
                  legitimate interests (to study how customers use our
                  products/services, to develop them and grow our business)
                </td>
              </tr>
              <tr>
                <td>
                  To administer and protect our business and this website
                  (including troubleshooting, data analysis, testing, system
                  maintenance, support, reporting and hosting of data){" "}
                </td>
                <td>(a) Identity (b) Contact (c) Technical</td>
                <td>
                  (a) Necessary for our legitimate interests (for running our
                  business, provision of administration and IT services, network
                  security, to prevent fraud and in the context of a business
                  reorganisation or group restructuring exercise) (b) Necessary
                  to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td>To deliver relevant website content to you</td>
                <td>
                  (a) Identity (b) Contact (c) Profile (d) Usage (e) Technical
                </td>
                <td>
                  Necessary for our legitimate interests (to study how customers
                  use our products/services, to develop them, and to grow our
                  business)
                </td>
              </tr>
              <tr>
                <td>
                  To use data analytics to improve our website,
                  products/services, customer relationships and experiences
                </td>
                <td>(a) Technical (b) Usage</td>
                <td>
                  Necessary for our legitimate interests (to define types of
                  customers for our products and services, to keep our website
                  updated and relevant, to develop our business and to inform
                  our marketing strategy)
                </td>
              </tr>
              <tr>
                <td>
                  To make suggestions and recommendations to you about goods or
                  services that may be of interest to you
                </td>
                <td>
                  (a) Identity (b) Contact (c) Technical (d) Usage (e) Profile
                </td>
                <td>
                  Necessary for our legitimate interests (to develop our
                  products/services and grow our business)
                </td>
              </tr>
            </tbody>
          </Table>
          <p className="pol__para">Promotional offers from us</p>
          <p className="pol__para">
            We may use your Identity, Contact, Technical, Usage and Profile Data
            to form a view on what we think you may want or need, or what may be
            of interest to you. This is how we decide which products, services
            and offers may be relevant for you.
          </p>
          <p className="pol__para">
            You may receive marketing communications from us if you have
            requested information from us or purchased goods or services from
            us, and in each case, you have not opted out of receiving that
            marketing.
          </p>
          <p className="pol__para">Opting out</p>
          <p className="pol__para">
            You can ask us or third parties to stop sending you marketing
            messages at any time by contacting us.
          </p>
          <p className="pol__para">
            Where you opt out of receiving these marketing messages, this will
            not apply to personal data provided to us as a result of a
            product/service purchase, warranty registration, product/service
            experience or other transactions.
          </p>
          <p className="pol__para">Change of purpose</p>
          <p className="pol__para">
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us.
          </p>
          <p className="pol__para">
            If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so.
          </p>
          <p className="pol__para">
            Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </p>
          <li className="pol__head">Disclosures of your personal data</li>
          <p className="pol__para">
            We may have to share your personal data with the parties set out
            below for the purposes set out in the table in paragraph 4 above.
          </p>
          <ul>
            <li>Internal Third Parties as set out in the Glossary.</li>
            <li>External Third Parties as set out in the Glossary.</li>
            <li>
              Third parties to whom we may choose to sell, transfer, or merge
              parts of our business or our assets. Alternatively, we may seek to
              acquire other businesses or merge with them. If a change happens
              to our business, then the new owners may use your personal data in
              the same way as set out in this privacy notice.
            </li>
          </ul>
          <p className="pol__para">
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our third-party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
          </p>
          <li className="pol__head">International transfers</li>
          <p className="pol__para">
            We share your personal data within the Company Group. This may
            involve transferring your data outside the European Economic Area
            (EEA).
          </p>
          <p className="pol__para">
            We ensure your personal data is protected by requiring all our group
            companies to follow the same rules when processing your personal
            data.
          </p>
          <p className="pol__para">
            Whenever we transfer your personal data out of the EEA, we ensure a
            similar degree of protection is afforded to it by ensuring at least
            one of the following safeguards is implemented:
          </p>
          <ul>
            <li>
              We may use specific contracts approved by the European Commission
              which give personal data the same protection it has in Europe.{" "}
            </li>
            <li>
              Where we use providers based in the US, we may transfer data to
              them if they are part of the Privacy Shield which requires them to
              provide similar protection to personal data shared between the
              Europe and the US.
            </li>
          </ul>
          <p className="pol__para">
            Please contact us if you want further information on the specific
            mechanism used by us when transferring your personal data out of the
            EEA.
          </p>
          <li className="pol__head">Data security</li>
          <p className="pol__para">
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality.
          </p>
          <p className="pol__para">
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </p>
          <li> Data retention</li>
          <p className="pol__para">
            How long will you use my personal data for?
          </p>
          <p className="pol__para">
            We will only retain your personal data for as long as necessary to
            fulfil the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.
          </p>
          <p className="pol__para">
            To determine the appropriate retention period for personal data, we
            consider the amount, nature, and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal requirements.
          </p>
          <p className="pol__para">
            By law we have to keep basic information about our customers
            (including Contact, Identity, Financial and Transaction Data) for
            six years after they cease being customers for tax purposes.
          </p>
          <p className="pol__para">
            In some circumstances you can ask us to delete your data: see
            Request erasure below for further information.
          </p>
          <p className="pol__para">
            In some circumstances we may anonymise your personal data (so that
            it can no longer be associated with you) for research or statistical
            purposes in which case we may use this information indefinitely
            without further notice to you.
          </p>
          <li className="pol__head">Your legal rights</li>
          <p className="pol__para">
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data. You have the right to:
          </p>
          <p className="pol__para">
            Request access to your personal data (commonly known as a “data
            subject access request”). This enables you to receive a copy of the
            personal data we hold about you and to check that we are lawfully
            processing it.
          </p>
          <p className="pol__para">
            Request correction of the personal data that we hold about you. This
            enables you to have any incomplete or inaccurate data we hold about
            you corrected, though we may need to verify the accuracy of the new
            data you provide to us.
          </p>
          <p className="pol__para">
            Request erasure of your personal data. This enables you to ask us to
            delete or remove personal data where there is no good reason for us
            continuing to process it. You also have the right to ask us to
            delete or remove your personal data where you have successfully
            exercised your right to object to processing (see below), where we
            may have processed your information unlawfully or where we are
            required to erase your personal data to comply with local law. Note,
            however, that we may not always be able to comply with your request
            of erasure for specific legal reasons which will be notified to you,
            if applicable, at the time of your request.
          </p>
          <p className="pol__para">
            Object to processing of your personal data where we are relying on a
            legitimate interest (or those of a third party) and there is
            something about your particular situation which makes you want to
            object to processing on this ground as you feel it impacts on your
            fundamental rights and freedoms. You also have the right to object
            where we are processing your personal data for direct marketing
            purposes. In some cases, we may demonstrate that we have compelling
            legitimate grounds to process your information which override your
            rights and freedoms.
          </p>
          <p className="pol__para">
            Request restriction of processing of your personal data. This
            enables you to ask us to suspend the processing of your personal
            data in the following scenarios: (a) if you want us to establish the
            data’s accuracy; (b) where our use of the data is unlawful but you
            do not want us to erase it; (c) where you need us to hold the data
            even if we no longer require it as you need it to establish,
            exercise or defend legal claims; or (d) you have objected to our use
            of your data but we need to verify whether we have overriding
            legitimate grounds to use it.
          </p>
          <p className="pol__para">
            Request the transfer of your personal data to you or to a third
            party. We will provide to you, or a third party you have chosen,
            your personal data in a structured, commonly used, machine-readable
            format. Note that this right only applies to automated information
            which you initially provided consent for us to use or where we used
            the information to perform a contract with you.
          </p>
          <p className="pol__para">
            Withdraw consent at any time where we are relying on consent to
            process your personal data. However, this will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is
            the case at the time you withdraw your consent.
          </p>
          <p className="pol__para">
            If you wish to exercise any of the rights set out above, please
            contact us.
          </p>
          <p className="pol__para">No fee usually required</p>
          <p className="pol__para">
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we may refuse to comply with your request
            in these circumstances.
          </p>
          <p className="pol__para">What we may need from you</p>
          <p className="pol__para">
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
          </p>
          <p className="pol__para">Time limit to respond</p>
          <p className="pol__para">
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
          <li className="pol__head">Glossary</li>
          <p className="pol__para">LAWFUL BASIS</p>
          <p className="pol__para">
            Legitimate Interest means the interest of our business in conducting
            and managing our business to enable us to give you the best
            service/product and the best and most secure experience. We make
            sure we consider and balance any potential impact on you (both
            positive and negative) and your rights before we process your
            personal data for our legitimate interests. We do not use your
            personal data for activities where our interests are overridden by
            the impact on you (unless we have your consent or are otherwise
            required or permitted to by law). You can obtain further information
            about how we assess our legitimate interests against any potential
            impact on you in respect of specific activities by contacting us
          </p>
          <p className="pol__para">
            Performance of Contract means processing your data where it is
            necessary for the performance of a contract to which you are a party
            or to take steps at your request before entering into such a
            contract.
          </p>
          <p className="pol__para">
            Comply with a legal or regulatory obligation means processing your
            personal data where it is necessary for compliance with a legal or
            regulatory obligation that we are subject to.
          </p>
          <p className="pol__para">THIRD PARTIES</p>
          <p className="pol__para">Internal Third Parties</p>
          <p className="pol__para">
            Other companies in the Eram Capital Partners Limited Group acting as
            joint controllers or processors and provide IT and system
            administration services and undertake leadership reporting.
          </p>
          <p className="pol__para">External Third Parties</p>
          <ul>
            <li>
              Service providers acting as processors who provide system
              administration services.
            </li>
            <li>
              Professional advisers acting as processors or joint controllers
              including lawyers, bankers, auditors and insurers based in the
              United Kingdom who provide consultancy, banking, legal, insurance
              and accounting services.
            </li>
            <li>
              HM Revenue & Customs, regulators and other authorities acting as
              processors or joint controllers based in the United Kingdom who
              require reporting of processing activities in certain
              circumstances.
            </li>
          </ul>
        </ol>
      </Container>
    </div>
  );
}

export default Policy;
