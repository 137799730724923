import React, { useState } from "react";
import ERAM from "../assets/images/capitallux-cropped.jpg";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./Navbar1.css";

function Navbar1() {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="nav__width">
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        className="navbar__main"
        id="navbar"
      >
        <Container style={{ marginLeft: "1.5%" }}>
          <Navbar.Brand href="/">
            <img src={ERAM} alt="" className="nav__image"></img>
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            aria-controls="responsive-navbar-nav"
            style={{ backgroundColor: "white" }}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>

            <Nav>
              <NavLink
                onClick={() => setExpanded(false)}
                to="/firm"
                className="nav-link"
                activeClassName="active"
              >
                Our Firm
              </NavLink>

              <NavDropdown
                title="Our Services"
                id="collasible-nav-dropdown"
                renderMenuOnMount={true}
              >
                <div className="drop_main">
                  <NavDropdown.Item className="drop__item">
                    <NavLink
                      onClick={() => setExpanded(false)}
                      className="nav-link"
                      activeClassName="active"
                      to="/advisory"
                    >
                      Financial Advisory
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="drop__item">
                    <NavLink
                      onClick={() => setExpanded(false)}
                      className="nav-link"
                      activeClassName="active"
                      to="/services"
                    >
                      Consulting Services
                    </NavLink>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <NavLink
                onClick={() => setExpanded(false)}
                className="nav-link"
                activeClassName="active"
                to="/contact"
              >
                Contact Us
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbar1;
